<template>
  <div class="container">
    <TitleBarBaseVue />
    <div class="handle_bar">
      <el-row :gutter="20" type="flex" justify="space-between">
        <el-col :xs="7" :sm="3" :md="2" :lg="2" :xl="2" class="flex">
          <el-button type="primary" @click="onAdd">添加</el-button>
        </el-col>
        <el-col :xs="24" :sm="10" :md="8" :lg="8" :xl="5" class="flex">
          <el-input v-model="params.keyWords" @change="changeParams" clearable placeholder="请输入名称关键词"> </el-input>
          <el-button class="margin-left-xs" type="primary" @click="handleSearch">搜索</el-button>
        </el-col>
      </el-row>
    </div>
    <el-table :data.sync="tableList" border row-key="categoryId" lazy :load="onTreeLoad" :tree-props="{ children: 'children', hasChildren: 'hadSecondCategory' }">
      <el-table-column label-class-name="header" prop="categoryId" label="类别编号" width="100" align="center"></el-table-column>
      <el-table-column prop="name" label="类别名称" align="center"></el-table-column>
      <el-table-column label="ICON图片" align="center">
        <template slot-scope="scope">
          <img :src="scope.row.iconImg" alt style="width:60px;height:60px;" />
        </template>
      </el-table-column>
      <el-table-column label="图片" align="center">
        <template slot-scope="scope">
          <img :src="scope.row.image" alt style="width:60px;height:60px;" />
        </template>
      </el-table-column>
      <el-table-column label="banner图片" align="center" width="220">
        <template slot-scope="scope">
          <img :src="scope.row.bannerImg" alt style="width:180px;height:60px;" />
        </template>
      </el-table-column>
      <el-table-column prop="sortNum" label="排序" align="center"></el-table-column>
      <el-table-column prop="createTime" label="创建时间" min-width="200" align="center">
        <template slot-scope="scope">
          {{ scope.row.createTime | dateformat('YYYY-MM-DD HH:mm:ss') }}
        </template>
      </el-table-column>
      <el-table-column prop="lastUpdateTime" label="更新时间" min-width="200" align="center">
        <template slot-scope="scope">
          {{ scope.row.lastUpdateTime | dateformat('YYYY-MM-DD HH:mm:ss') }}
        </template>
      </el-table-column>
      <el-table-column label="状态" width="100" align="center">
        <template slot-scope="scope">
          <el-button @click="onUpdateStatus(scope.row)" title="点击显示" v-if="scope.row.isHide == 1" size="mini" type="warning">已隐藏</el-button>
          <el-button @click="onUpdateStatus(scope.row)" title="点击隐藏" v-else size="mini" type="primary">已显示</el-button>
        </template>
      </el-table-column>
      <el-table-column prop label="操作" align="center">
        <template slot-scope="scope">
          <el-button size="mini" type="success" @click="onEidt(scope.row)" :disabled="scope.row.status == 0 ? true : false">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pageCo">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.pageNo"
        :page-sizes="[1, 2, 5, 10, 20]"
        :page-size="params.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      />
    </div>
  </div>
</template>

<script>
import TitleBarBaseVue from '@/components/TitleBar/TitleBarBase.vue'
import { categoryPage, categorySecondList, categoryUpdate } from '@/api/GoodsController'
export default {
  data() {
    return {
      params: {
        keyWords: '',
        pageNo: 1,
        pageSize: 10
      },
      total: 0,

      tableList: []
    }
  },

  mounted() {
    this.fecthList()
  },

  methods: {
    changeParams(v) {
      !v && this.handleSearch()
    },

    handleSearch() {
      console.log(this.params)
      this.fecthList()
    },

    async fecthList() {
      let [err, res] = await categoryPage(this.params)
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '获取列表失败')
      }
      console.log(res)
      this.tableList = res.data.records
      this.total = res.data.total
    },
    // 监听 pageSize 发生改变
    handleSizeChange(newSize) {
      this.params.pageSize = newSize
      this.fecthList()
    },
    // 监听 页码值 发生改变
    handleCurrentChange(newPage) {
      this.params.pageNo = newPage
      this.fecthList()
    },

    //查询二级分类列表
    async onTreeLoad(row, treeNode, resolve) {
      console.log(row, treeNode)
      let param = {
        categoryId: row.categoryId
      }
      let [err, res] = await categorySecondList(param)
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '获取二级列表失败')
      }
      console.log(res)
      resolve(res.data || [])
    },

    //更新状态
    async onUpdateStatus(row) {
      let param = {
        categoryId: row.categoryId,
        isHide: row.isHide == 1 ? 0 : 1
      }
      let [err, res] = await categoryUpdate(param)
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '操作失败')
      }
      this.fecthList()
    },

    //新建
    onAdd() {
      this.$router.push({
        path: '/categoryEdit'
      })
    },

    //编辑
    onEidt(row) {
      sessionStorage.setItem('category', JSON.stringify(row))
      this.$router.push({
        path: '/categoryEdit',
        query: {
          categoryId: row.categoryId
        }
      })
    }
  },

  components: {
    TitleBarBaseVue
  }
}
</script>
<style lang="less" scoped>
/deep/ .el-table__row--level-1 {
  background-color: rgb(248, 248, 248);
}
</style>
